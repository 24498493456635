import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import { Button, ContextMenu, ContextMenuProps, H, Helper, SlidePanel, Ui } from "common/components/atoms";
import StakeholdersLimitSubmitModal from "common/components/atoms/StakeholdersLimitSubmitModal/StakeholdersLimitSubmitModal";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, DownloadIcon, UploadIcon } from "common/icons/svg";
import { downloadExcelFile } from "common/utils/download";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ImportFromExcelCanvas from "../ImportFromExcelCanvas/ImportFromExcelCanvas";
import { OwnershipValues } from "../OwnershipTable/OwnershipTable";

const t = createTranslation(TranslationNS.pages, "onboard.company.importOwnership");

type OwnershipActionsProps<T> = {
  handleImportData: (handleImportData: T[]) => void;
  setCompletedOnboarding: (value: boolean) => void;
  backButtonHidden?: boolean;
  isStakeholdersLimitModalOpen: boolean;
  setIsStakeholdersLimitModalOpen: (value: boolean) => void;
};

declare global {
  interface String {
    capitalize(): string;
  }
}

Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

const OwnershipActions = <T,>({
  handleImportData,
  setCompletedOnboarding,
  backButtonHidden,
  isStakeholdersLimitModalOpen,
  setIsStakeholdersLimitModalOpen,
}: OwnershipActionsProps<T>) => {
  const { i18n } = useTranslation();
  const { companyId } = useParams<{ companyId?: string }>();
  const { subscription } = useStripeSubscription();

  const [show, setShow] = useState(false);

  const handleManageSidebar = useCallback(() => setShow((old) => !old), []);

  const { values, handleSubmit, isSubmitting } = useFormikContext<OwnershipValues[]>();

  const { regular, bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);

  const backPath =
    bregg || uniMicro
      ? getPath(["onboard", "company", "companyInformation"], { companyId })
      : getPath(["onboard", "company", "shareClasses"], { companyId });

  const type = regular?.withTransaction ? "stakeholders" : "shareholders";

  const handleGetExample = useCallback(() => {
    downloadExcelFile(`/api/export-template/${type}/${companyId}`, `Import ${type} company ${companyId}`).then();
  }, [companyId, type]);

  const items = useMemo<ContextMenuProps["items"]>(
    () => [
      {
        label: t("downloadTemplate"),
        key: "view",
        icon: <DownloadIcon />,
        onClick: handleGetExample,
      },
      {
        label: t("importFromExcel"),
        key: "delete",
        icon: <UploadIcon />,
        onClick: handleManageSidebar,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetExample, handleManageSidebar, i18n.language]
  );

  const submit = () => {
    setCompletedOnboarding(true);
    handleSubmit();
  };

  const submitCheck = () => {
    if (subscription?.maximumStakeholders && subscription.maximumStakeholders < values.length) {
      setIsStakeholdersLimitModalOpen(true);
    } else {
      submit();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between align-items-end">
        <div style={{ flex: 1 }}>
          {backButtonHidden ? null : (
            <Link to={backPath}>
              <Button as="span" variant="tertiary" isOnlyIcon>
                <ArrowLeftIcon />
              </Button>
            </Link>
          )}

          <ContextMenu items={items} drop="down" chevronAnimation>
            <Button variant="tertiary" iconRight={<ChevronDownIcon className="chevron" />}>
              {t("import")}
            </Button>
          </ContextMenu>
        </div>
        <div style={{ flex: 1 }} className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <Helper>
              <Helper.Question questionId="import">
                <H.l>{t(`import${type.capitalize()}`)}</H.l>
              </Helper.Question>

              <Helper.Answer answerId="import">
                {bregg || uniMicro ? t(`import${type.capitalize()}Helper`) : t("helperText")}
              </Helper.Answer>
            </Helper>
            {bregg && (
              <Ui.s className="mt-1 text-center" color="foregroundLow">
                {t("lastUpdated", { date: "31.12.2023" })}
              </Ui.s>
            )}
          </div>
        </div>
        <div style={{ flex: 1 }} className="text-end">
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={() => setCompletedOnboarding(false)}
            type="submit"
            variant="tertiary"
          >
            {t("later")}
          </Button>
          <Button
            isOnlyIcon
            className="ms-3"
            variant="primary"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={submitCheck}
          >
            <ArrowRightIcon />
          </Button>
        </div>
      </div>

      <SlidePanel show={show} onHide={handleManageSidebar}>
        <ImportFromExcelCanvas
          api={`/api/import/onboarding/${type}`}
          keyParam={type}
          onClose={handleManageSidebar}
          handleImportData={handleImportData}
        />
      </SlidePanel>

      <StakeholdersLimitSubmitModal
        isOpen={isStakeholdersLimitModalOpen}
        handleSubmit={submit}
        handleClose={() => setIsStakeholdersLimitModalOpen(false)}
        isLoading={isSubmitting}
      />
    </Form>
  );
};

export default OwnershipActions;
