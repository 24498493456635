import { FC, useCallback, useEffect, useState } from "react";
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import cn from "classnames";
import { FormikErrors, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import { ImportErrorHandler, ImportTable } from "common/components/atoms/ImportTable";
import { manageRow } from "common/components/atoms/ImportTable/ImportTableHelper";
import StickyActions from "common/components/atoms/ImportTable/StickyActions/StickyActions";
import StakeholdersLimitBanner from "common/components/atoms/StakeholdersLimitBanner/StakeholdersLimitBanner";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { PlusIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { useTableBrowserStorage } from "../../../../../common/components/atoms/ImportTable/TableBrowserStorage";
import { OwnershipFormValues } from "../useImportOwnershipForm";
import classes from "./OwnershipTable.module.scss";

type OwnershipTableProps = {
  columns: ColumnDef<OwnershipFormValues>[];
  initialItems: OwnershipFormValues;
  countOfActiveCheckboxes: number;
};

const t = createTranslation(TranslationNS.pages, "onboard.company.importOwnership");

export type OwnershipValues = OwnershipFormValues & { checkbox?: boolean };

const OwnershipTable: FC<OwnershipTableProps> = ({ columns, initialItems, countOfActiveCheckboxes }) => {
  const withTransaction = useStoreState((state) => state.companyOnboardingModel.integrations?.regular)?.withTransaction;
  const { subscription } = useStripeSubscription();

  const { values, setValues, isSubmitting, isValid } = useFormikContext<OwnershipValues[]>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [listErrors, setListErrors] = useState<FormikErrors<OwnershipFormValues[]>>([]);

  const [globalFilter, setGlobalFilter] = useState("");

  const { setStakeholderLimitModalOpen } = useStoreActions((actions) => actions.companyOnboardingModel);

  const errorFilterData: FilterFnOption<OwnershipFormValues> = useCallback(
    (row: Row<OwnershipFormValues>) => {
      return !!listErrors[Number(row?.id)];
    },
    [listErrors]
  );

  const updateData = useCallback(
    (rowIndex: number, type: string) => {
      setValues(manageRow(values, rowIndex, type, initialItems, ["stakeholderId", "shareholderId", "issuedShareId"]));
    },
    [initialItems, setValues, values]
  );

  const table = useReactTable({
    data: values,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { updateData, footerShow: true },
    state: {
      globalFilter: globalFilter,
    },
    globalFilterFn: errorFilterData,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleAddNewRow = useCallback(() => {
    if (subscription?.maximumStakeholders && subscription.maximumStakeholders < values.length) {
      setStakeholderLimitModalOpen(true);
    } else {
      setValues([...values, { ...initialItems }]);
    }
  }, [initialItems, setStakeholderLimitModalOpen, setValues, subscription?.maximumStakeholders, values]);

  const stakeholdersLimitReached =
    subscription?.maximumStakeholders && subscription.maximumStakeholders < values.length;

  useEffect(() => {
    if (isSubmitting) {
      setShowErrorMessage(!isValid);
    }
  }, [isSubmitting, isValid]);

  const { setImportTable } = useTableBrowserStorage();

  const saveBeforeUpgrade = () => {
    setImportTable(values);
  };

  return (
    <>
      <div className={classes.content}>
        {showErrorMessage && (
          <ImportErrorHandler
            updateErrorCallback={setListErrors}
            isFilterActive={!!globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {stakeholdersLimitReached && <StakeholdersLimitBanner asToast onUpgrade={saveBeforeUpgrade} />}

        <ImportTable table={table} tableType="founders" />
        <div className={cn(classes.bottomActions, "mt-4")}>
          {!globalFilter ? (
            <Button isDisabled={!!globalFilter} onClick={handleAddNewRow} iconLeft={<PlusIcon />} variant="secondary">
              {withTransaction ? t("addStakeholders") : t("addNewShareholder")}
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
      <StickyActions countOfActiveCheckboxes={countOfActiveCheckboxes} />
    </>
  );
};
export default OwnershipTable;
