import { FC, useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum } from "common/enums/enum";
import { InformationCircleIcon } from "common/icons/svg";
import { OwnershipProgramTableData, sortingParams } from "common/types/Collapsible.types";
import { sortProgramTableData } from "common/utils/functions";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import { PlanStatuses } from "../../../../ownership/documents/documents.types";
import ManagePlansContext, {initialFiltersState} from "../../managePlansContext";
import OneOffPlansHeader from "./OneOffPlansHeader";
import classes from "./ProgramContainer.module.scss";
import ProgramFooterContent from "./ProgramFooterContent";
import ProgramHeader from "./ProgramHeader";
import ProgramTableContent from "./ProgramTableContent";
import ProgramTableHead from "./ProgramTableHead";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");

type commonProgramContainerProps = {
  defaultOpen?: boolean;
  cliff?: number;
  programID?: number;
  data: OwnershipProgramTableData[];
  onHeadPress?: (index?: number) => void;
  onGrantAllPlansPress?: (programId: number) => void;
  index?: number;
};

type ProgramContainerProps = commonProgramContainerProps &
  (
    | {
        title: string;
        status: string;
        description: string;
        vestingPeriod: number;
        vestingPeriodTimeUnit: string;
        stockType: InstrumentTypesNamesEnum;
        stockTypeId: InstrumentTypesIdsEnum;
        isOneOffIndividualPlan?: boolean;
        cliffTimeUnit?: string;
      }
    | {
        title?: string;
        status?: string;
        description?: string;
        vestingPeriod?: number;
        vestingPeriodTimeUnit?: string;
        stockTypeId?: InstrumentTypesIdsEnum;
        stockType?: InstrumentTypesNamesEnum;
        isOneOffIndividualPlan: true;
        cliffTimeUnit?: string;
      }
  );

export type CurrentSortValue = {
  field: keyof OwnershipProgramTableData;
  type: keyof typeof sortingParams;
};

const ProgramContainer: FC<ProgramContainerProps> = ({
  data = [],
  title,
  status,
  programID,
  stockTypeId,
  stockType,
  description,
  vestingPeriod,
  vestingPeriodTimeUnit,
  cliffTimeUnit,
  cliff,
  isOneOffIndividualPlan = false,
}) => {
  const { companyId = "0" } = useParams();

  const navigate = useNavigate();
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const appliedFilters = savedAppliedFilters?.[companyId];

  const isStockOptions = stockTypeId === InstrumentTypesIdsEnum.OPTION;

  const [sortedValueActive, setSortedValueActive] = useState<CurrentSortValue>({
    field: "none",
    type: sortingParams.inc,
  });

  const filteredPlans = useMemo(() => {
    const statuses = defaultTo(initialFiltersState.statuses, appliedFilters?.statuses) as any;
    const activeStatuses = Object.keys(statuses)
      .filter((status) => statuses?.[status])
      ?.map((el) => el?.toLowerCase());

    return data?.filter((plan) => {
      let initialIncludingLogic = activeStatuses?.includes((plan?.statusName as any)?.toLowerCase());

      if (appliedFilters?.dates?.expiryDate?.from) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan.optionsExpirationDate
            ? new Date(plan.optionsExpirationDate) >= new Date(appliedFilters?.dates?.expiryDate?.from)
            : false);
      }

      if (appliedFilters?.dates?.expiryDate?.to) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan.optionsExpirationDate
            ? new Date(plan.optionsExpirationDate) <= new Date(appliedFilters?.dates?.expiryDate?.to)
            : false);
      }

      if (appliedFilters?.dates?.startDate?.from) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan.startDate ? new Date(plan.startDate) >= new Date(appliedFilters?.dates?.startDate?.from) : false);
      }

      if (appliedFilters?.dates?.startDate?.to) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan.startDate ? new Date(plan.startDate) <= new Date(appliedFilters?.dates?.startDate?.to) : false);
      }

      if (appliedFilters?.dates?.endDate?.from) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan?.endTime ? new Date(plan.endTime) >= new Date(appliedFilters?.dates?.endDate?.from) : false);
      }

      if (appliedFilters?.dates?.endDate?.to) {
        initialIncludingLogic =
          initialIncludingLogic &&
          (plan.endTime ? new Date(plan.endTime) <= new Date(appliedFilters?.dates?.endDate?.to) : false);
      }

      if (!isNil(appliedFilters?.selectedStakeholder)) {
        initialIncludingLogic =
          initialIncludingLogic && plan?.stakeholderId === appliedFilters?.selectedStakeholder?.stakeholderId;
      }

      return initialIncludingLogic;
    });
  }, [
    appliedFilters?.dates?.endDate?.from,
    appliedFilters?.dates?.endDate?.to,
    appliedFilters?.dates?.expiryDate?.from,
    appliedFilters?.dates?.expiryDate?.to,
    appliedFilters?.dates?.startDate?.from,
    appliedFilters?.dates?.startDate?.to,
    appliedFilters?.selectedStakeholder,
    appliedFilters?.statuses,
    data,
  ]);

  const rows = useMemo(
    () => [
      {
        key: "representedBy",
        value: t("table.planOwner"),
      },
      ...(isOneOffIndividualPlan ? [{ key: "type", value: t("table.type") }] : []),
      ...(isStockOptions || isOneOffIndividualPlan ? [{ key: "exercisePrice", value: t("table.exercisePrice") }] : []),
      {
        key: "vestedShares",
        value: t("table.progress"),
      },
      ...(isStockOptions || isOneOffIndividualPlan ? [{ key: "exercisedShares", value: t("table.exercised") }] : []),
      {
        key: "startDate",
        value: t("table.startDate"),
      },
      {
        key: "endTime",
        value: t("table.endDate"),
      },
      {
        key: "optionsExpirationDate",
        value: t("table.expiryDate"),
      },
      {
        key: "statusName",
        value: t("table.status"),
      },
      {
        key: "invitationSent",
        value: t("table.invited"),
      },
      {
        key: "terms",
        value: t("table.terms"),
      },
    ],
    [isOneOffIndividualPlan, isStockOptions]
  );

  const sortedActivePlansTableData = useMemo(
    () =>
      sortProgramTableData({
        data: filteredPlans,
        sortedField: sortedValueActive.field,
        sortType: sortedValueActive.type,
      }),
    [filteredPlans, sortedValueActive]
  );

  const handleAddPlan = useCallback(() => {
    if (isOneOffIndividualPlan) {
      navigate(getEMPath(["createOneOffPlan", "planReceiver"]));
      return;
    }

    navigate(getEMPath(["createPlan", "basics"], { programId: programID }));
  }, [navigate, isOneOffIndividualPlan, programID]);

  const PlanTableHead = useCallback(() => {
    return <ProgramTableHead rows={rows} sortedValue={sortedValueActive} setSortedValue={setSortedValueActive} />;
  }, [sortedValueActive, rows]);

  const PlanBodyTableData = useCallback(() => {
    return (
      <ProgramTableContent programID={programID} data={sortedActivePlansTableData} isOneOff={isOneOffIndividualPlan} />
    );
  }, [isOneOffIndividualPlan, programID, sortedActivePlansTableData]);

  const ProgramFooterTableData = useCallback(() => {
    return (
      <ProgramFooterContent isSO={isStockOptions} isOneOff={isOneOffIndividualPlan} data={sortedActivePlansTableData} />
    );
  }, [isOneOffIndividualPlan, isStockOptions, sortedActivePlansTableData]);

  return (
    <>
      <ExpansionPanel.Toggle eventKey={`${programID || "one-off"}`}>
        {isOneOffIndividualPlan ? (
          <OneOffPlansHeader data={data} handleAddPlan={handleAddPlan} />
        ) : (
          <ProgramHeader
            title={title}
            status={status}
            stockType={stockType}
            description={description}
            vestingPeriod={vestingPeriod}
            vestingPeriodTimeUnit={vestingPeriodTimeUnit}
            cliffTimeUnit={cliffTimeUnit}
            cliff={cliff}
            data={data}
            handleAddPlan={handleAddPlan}
          />
        )}
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey={`${programID || "one-off"}`} className={classes.programContainer}>
        <div className="program-table-header">
          <>
            {!isEmpty(filteredPlans) ? (
              <div className={classes.table}>
                <Table
                  headComponent={<PlanTableHead />}
                  bodyComponent={<PlanBodyTableData />}
                  footComponent={<ProgramFooterTableData />}
                />
              </div>
            ) : (
              <div className={cn(classes.empty)}>
                <div className={classes.infoBlock}>
                  <div className={classes.icon}>
                    <InformationCircleIcon />
                  </div>
                  {status === PlanStatuses.draft ? (
                    <>
                      <Ui.xs>{t("ownershipPrograms.draftPlanPart1")}</Ui.xs>
                      <Link to={getEMPath(["plans", "poolsAndPrograms"])}>
                        <Ui.xs className={classes.link}>{t("ownershipPrograms.draftPlanPart2")}</Ui.xs>
                      </Link>
                      <Ui.xs>{t("ownershipPrograms.draftPlanPart3")}</Ui.xs>
                    </>
                  ) : (
                    <Ui.xs>{t("ownershipPrograms.noOwnershipPlans")}</Ui.xs>
                  )}
                </div>
              </div>
            )}
          </>
        </div>
      </ExpansionPanel.Collapse>
    </>
  );
};

ProgramContainer.displayName = "Single Program Container";

export default ProgramContainer;
