import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";
import { isEmpty } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, ContextMenu, ContextMenuProps, H, Helper, SlidePanel } from "common/components/atoms";
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, DownloadIcon, UploadIcon } from "common/icons/svg";
import { downloadExcelFile } from "common/utils/download";
import { OnboardingTransaction } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import ImportFromExcelCanvas from "../../import-ownership/ImportFromExcelCanvas/ImportFromExcelCanvas";
import TransactionModal from "../TransactionModal/TransactionModal";

const t = createTranslation(TranslationNS.pages, "onboard.company.importTransaction");

type TransactionActionsProps = {
  setCompletedOnboarding: (value: boolean) => void;
  handleImportData: (data: OnboardingTransaction[]) => void;
};

const TransactionActions: FC<TransactionActionsProps> = ({ setCompletedOnboarding, handleImportData }) => {
  const { i18n } = useTranslation();
  const { companyId } = useParams<{ companyId?: string }>();
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);

  const handleManageSidebar = useCallback(() => setShowImport((old) => !old), []);

  const { handleSubmit, isSubmitting, errors } = useFormikContext();

  const handleGetExample = useCallback(() => {
    downloadExcelFile(
      `/api/export-template/transactions/${companyId}`,
      `Import transactions company ${companyId}`
    ).then();
  }, [companyId]);

  const handleManageShow = useCallback(() => {
    handleSubmit();
    if (isEmpty(errors)) {
      setShow((prev) => !prev);
    }
  }, [errors, handleSubmit]);

  const items = useMemo<ContextMenuProps["items"]>(
    () => [
      {
        label: t("downloadTemplate"),
        key: "view",
        icon: <DownloadIcon />,
        onClick: handleGetExample,
      },
      {
        label: t("importFromExcel"),
        key: "delete",
        icon: <UploadIcon />,
        onClick: handleManageSidebar,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGetExample, handleManageSidebar, i18n.language]
  );

  const handleSaveAndContinue = useCallback(() => {
    setCompletedOnboarding(false);
  }, [setCompletedOnboarding]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between align-items-end">
        <div style={{ flex: 1 }}>
          <Link to={getPath(["onboard", "company", "importStakeholders"], { companyId })}>
            <Button as="span" variant="tertiary" isOnlyIcon>
              <ArrowLeftIcon />
            </Button>
          </Link>
          <ContextMenu items={items} drop="down" chevronAnimation>
            <Button variant="tertiary" iconRight={<ChevronDownIcon className="chevron" />}>
              {t("import")}
            </Button>
          </ContextMenu>
        </div>
        <div style={{ flex: 1 }} className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <Helper>
              <Helper.Question questionId="import">
                <H.l>{t("addHistoricTransactions")}</H.l>
              </Helper.Question>

              <Helper.Answer answerId="import">{t("helperText")}</Helper.Answer>
            </Helper>
          </div>
        </div>
        <div style={{ flex: 1 }} className="text-end">
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleSaveAndContinue}
            variant="tertiary"
            type="submit"
          >
            {t("later")}
          </Button>
          <Button
            isOnlyIcon
            className="ms-3"
            variant="primary"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleManageShow}
          >
            <ArrowRightIcon />
          </Button>
        </div>
      </div>
      <SlidePanel show={showImport} onHide={handleManageSidebar}>
        <ImportFromExcelCanvas
          api={"/api/import/onboarding/transactions"}
          keyParam={"transactions"}
          onClose={handleManageSidebar}
          handleImportData={handleImportData}
        />
      </SlidePanel>
      <TransactionModal show={show} handleClose={handleManageShow} setCompletedOnboarding={setCompletedOnboarding} />
    </Form>
  );
};

export default TransactionActions;
